import { useEffect, useState }                from 'react'
import { getFocus, useAtom, useGet }                 from 'state/jotai'
import { active_project, share_content }           from 'state/store.global'
import { vis_share }               from 'state/store.global'
import { Backdrop }                from 'styles/styles.backdrop'
import { STATICS }                 from 'api/api'
import { Icon }                    from 'assets/Icons_'
import { Button, ClickAwayListener, Stack, Tooltip }           from '@mui/material'
import { Container, Inner, Input } from './Share.Portal.style'
import { useTranslation }          from 'react-i18next'
import { isMobile }                from 'react-device-detect'

import { FacebookShareButton, FacebookMessengerShareButton } from 'react-share'
import { _project } from 'state/store.projects'
import { useActiveUnit } from 'components/Unit'

const data = {
  Header1 : 'Del bolig',
  Text1   : 'Forestiller du dig at denne bolig er det perfekte match til en person i dit netværk? Del den med det samme.',
  Header2 : 'Indtast email',
}

export const SharePortal = () => {

  const content             = useAtom(share_content)
  const setShare            = useAtom(vis_share)[1]
  const { i18n:{language} } = useTranslation()
  const [email, setEmail]   = useState()
  const [shareTooltip, setShareTooltip] = useState(false);

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  
  const close   = { icon: 'close6', color: '#fff', wrap: { position: 'absolute', top: 20, right: 20 }, size: '20', click: () => setShare(false), }
  const homeUrl = window.location.origin;
  const unitUrl = `${homeUrl}/${language}/unit/${content[0]}`;
  const unit        = useActiveUnit();
  const searchUnitUrl = `https://juliliving.dk/banehaverne-bolig/?boligID=${unit.getId()}`;
  //console.log("searchUnitUrl",searchUnitUrl)
  let clearTooltip;
  const inputChange = e => { setEmail(e.target.value) }
  const handleDelBolig = () => {
    const subject = 'Bolig'
    const message = searchUnitUrl
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`
    setShare(false)
  }
  
  const handleCopyToClipboard = () => { 
    navigator.clipboard.writeText(searchUnitUrl) 
    setShareTooltip(true)
    clearTimeout(clearTooltip)
    setTimeout(()=> setShareTooltip(false), 3000)
    // setShare(false)
  }

  const handleTooltipClose = () => {
    clearTimeout(clearTooltip)
    setShareTooltip(false)
  }

  const handleSMS = () => {
    const message = searchUnitUrl
    window.location.href = `sms:?&body=${message}`
  }


  return (
    <>
      <Backdrop onClick={() => { setShare(false) }} />
      <Container>
        <img src={`${STATICS}generic.png`} alt='' />
        <Icon {...close} />
        <Inner>
          <h2>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'delBoligOverskrift')[0]?.Label }</h2>
          <p>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'delBoligUnderoverskrift')[0]?.Label }</p>
          <div style={{ height: 20 }} />
          <h3>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'delBoligemailOverskrift')[0]?.Label }</h3>
          <Input type='text' placeholder='email@email.dk' onChange={inputChange}></Input>
          <div style={{ height: 20 }} />
          <Stack direction='row' spacing='1px' justifyContent='flex-start'>
            <Button variant='contained' size='large'style={{color:"#FFF"}} onClick={handleDelBolig}>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'delBolig')[0]?.Label }</Button>
          </Stack>
          <div style={{height:40}}/>
          <div style={{display:'flex',gap:20}}>
            {/* <FacebookShareButton url={searchUnitUrl}><Icon icon='facebook' color='#000' size='30'/></FacebookShareButton>
            <FacebookMessengerShareButton url={searchUnitUrl}><Icon icon='messenger' color='#000' size='30'/></FacebookMessengerShareButton> */}
            <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                PopperProps={{
                  disablePortal: true,
                  sx: { 
                    'div': {
                      padding: '5px 10px',
                      backgroundColor:"black"
                    },
                    'span': {
                      color:"black"
                    }
                  }
                }}
                arrow 
                //disablePadding
                onClose={handleTooltipClose}
                open={shareTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Linket af kopieret til udklipsholder"
              >
                <Button sx={{ minWidth: "unset", padding: "0" }} onClick={handleCopyToClipboard}> <Icon icon='link' color='#000' size='30'/></Button>
              </Tooltip>
            </ClickAwayListener>
            {isMobile && <Icon icon='sms' color='#000' size='30' click={handleSMS}/> }
          </div>
          <div style={{height:40}}/>
        </Inner>
      </Container>
    </>
  )

}

