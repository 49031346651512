import { useMount }           from 'hooks/useMount'
import eachOfLimit            from 'async/eachOfLimit'
import { STATICS, FLOORPLAN } from 'api/api'
import { useAtom }            from 'state/jotai'
import { static_assets }      from 'state/store.global'

const obj = {
  'logo_desktop' : `${STATICS}logo_desktop.svg`,
  'logo_mobile'  : `${STATICS}logo_mobile.svg`,
  // 'realtor_1'    : `${STATICS}realtor_1.svg`,
  'realtor_2'    : `${STATICS}realtor_2.svg`,
  'favicon_svg'  : `${STATICS}favicon.svg`,
  'favicon_ico'  : `${STATICS}favicon.ico`,
  'default_img'  : `${FLOORPLAN}`,
  // 'gradient'     : `${STATICS}gradient.jpg`,
  'generic'      : `${STATICS}generic.png`,
  // 'favicon_png'  : `${STATICS}favicon.png`,
  // 'favicon_png'  : `${STATICS}favicon.png`,
}

export const usePreload_Logos = () => {
  
  const [ assets, setAssets ] = useAtom(static_assets)

  const keys  = Object.keys(obj)
  const col   = Object.values(obj)
  const asset = {}
  const res   = Object.keys(assets || {}).length ? true : false
  
  useMount(() => preload(col,asset,keys,setAssets) )

  return { assets, res }
} 

const preload = (col,asset,keys,setAssets) => {
  const iteratee = (i, j, cb) => cache(i, j, cb, setAssets, asset, keys)
  // const complete = () => { console.log( 'preload assets done!' ) }
  const complete = () => { }
  eachOfLimit( col, 4, iteratee, complete )
}

const cache = (i,j,cb,setState,asset,nam) => {
  const img = new Image()
  img.src = i
  img.onload = cb()
  asset[nam[j]] = img
  setState( asset )
}