import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRelatedUnits } from "./hooks";
import Unit from "./Unit.model";
import UnitCard from "./UnitCard";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function RelatedUnits({ unit }) {
  const sliderBreakPoints = {
    300: {
      slidesPerView: 1.3
    },
    500: {
     slidesPerView: 2,
    },
    1400: {
     slidesPerView: 3,
     },
     2000: {
       slidesPerView: 4,
     },
  }
  
  const relatedUnits = useRelatedUnits(unit.getId());
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigateTo = useNavigate()

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const { i18n: { language } } = useTranslation()

  const handleClick = () => navigateTo(`/${language}/list`)

  return (
    <Box
      sx={{
        px: isMobile ? 3 : 6,
        pt: 4,
        width: isMobile ? '100%' : '90%'
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: 700, mb: 3, fontSize: "1.25rem" }}
      >
        { buttonsLabel?.filter( btn => btn.UILabelProperty === 'MåskeTekst')[0]?.Label }
      </Typography>
      {!relatedUnits.length && <Box sx={{textAlign: 'center', marginTop: '3rem'}}>
        Der er ikke nogle lignende boliger, der er ledige
      </Box>}
      <>
        <Swiper spaceBetween={isMobile ? 20 : 50} breakpoints = {sliderBreakPoints} >
          {relatedUnits.map((unit, i) => (
            <SwiperSlide key={i}>
              {/* <RelatedUnitCard unit={unit} /> */}
              <UnitCard unit={unit} />
            </SwiperSlide>
          ))}
        </Swiper>
      </>

      <Box
        sx={{
          mt: 4,
          textAlign: "center",
        }}
      >
        <Button clr={clr} variant="contained" style={{color:clr.buttons_text}}  onClick={handleClick}>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'SeAlleBoliger')[0]?.Label }</Button>
      </Box>
    </Box>
  );
}

RelatedUnits.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default RelatedUnits;
