import { Button }          from './Filter.Controls.style'
import { useToggle }       from './Filter.Controls.MultiSelect.Toggle'

export const MultiSelect = ({ Label, PossibleValues, active, setFilters })  => {

  const state = active?.multiSelect?.find(i=>i.property == Label)?.values ?? []
  const toggle = useToggle({ active, setFilters, state, Label })
  
  return (
    <>
    { 
      PossibleValues.map( ({ ValueProperty, Value }, j ) => {

        const isSelected = state?.includes(ValueProperty)
        const isEqualTo1 = state.length === 1
        const onClick    = ()=>toggle(ValueProperty,isSelected,isEqualTo1)
     
        return (
          <Button
            key        = {j}
            isSelected = {isSelected}
            onClick    = {onClick}
            children   = {Value}
            clr        = {clr}
          />
        )
      })
    }
    </>
  )
}