import { Box, Button } from "@mui/material";
import React from "react";
import { animateScroll } from "react-scroll";
import useUnitPage from "./useUnitPage";
import { _project } from "state/store.projects";
import { active_project } from "state/store.global";
import { getFocus, useGet } from "state/jotai";

function CTA() {
  const [refContainer] = useUnitPage();

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const handleClick = () => {
    const targetElement = document.getElementById("unit_page_contact_form");

    if (targetElement) {
      const offset = targetElement.offsetTop;
      animateScroll.scrollTo(offset, {
        container: refContainer,
      });
    }
  };

  return (
    <Box textAlign="center" >
      <Button
      clr={clr}
      style={{color:clr.buttons_text}}
        variant="contained"
        color="primary"
        size="large"
        onClick={handleClick}
      >
        { buttonsLabel?.filter( btn => btn.UILabelProperty === 'Kontakt')[0]?.Label }
      </Button>
    </Box>
  );
}

CTA.propTypes = {};

export default CTA;
