import { useEffect } from "react";
import {
  Svg,
  Circle,
  Group,
  Line,
  Pos,
  Lab,
  Outer,
  Text1,
  Text2,
  Path,
  Text3,
  CircleGroup,
} from "./Overview.label.style";

import { useLocation } from "react-router-dom";
import { useHover } from "hooks/useHover";
import { useAtom } from "jotai";
import { a_labHovered, active_project } from "state/store.global";
import { useClick } from "./Overview.label.click";
import { _project } from "state/store.projects";
import { getFocus, useGet } from "state/jotai";

export const MobileLabel = ({
  c,
  sc,
  d,
  l,
  rmode,
  r,
  labelsInfo,
  line,
  angle,
  mobileLabelAxis,
}) => {
  if (
    c === undefined ||
    r === undefined ||
    mobileLabelAxis === undefined ||
    angle === undefined ||
    line === undefined
  )
    return null;

  const here = useLocation().pathname.split("/");

  const setLabHovered = useAtom(a_labHovered)[1];
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  // console.log(buttonsLabel,"kjkk")
  const unitStatus = getFocus(_project, "unitStatuses")?.[pin]?.[pid] ?? [];
  const availableTranslation =
    unitStatus?.filter((u) => u.ID === 10)?.[0]?.Name ?? "";

  const isOverview = here[here.length - 1] == "isometry";

  const [hoverRef, isHovered] = useHover();
  const click = useClick();

  if (c === undefined || r === undefined) return null;

  const s = 0.6 / sc === Infinity ? 10 : 0.6 / sc;

  const svg = { viewBox: "0 0 100 100" };
  const circ1 = { cx: 0, cy: 0, r: 15, clr: clr };
  const circ2 = { cx: 0, cy: 0, r: 25, fillOpacity: "0.5", clr: clr };
  const circ3 = { cx: 0, cy: 0, r: 35, fillOpacity: "0.3", clr: clr };

  const circleGroup = { transform: `translate(0 0)` };
  const path1 = { d: `M 0 0 L ${line[0]} ${line[1]}`, clr: clr };

  const dl = { d, l, s };
  const line_angle = { transform: `rotate(${angle})` };

  const labelText =
    labelsInfo?.available_Units === 0
      ? `${buttonsLabel
          ?.filter((btn) => btn.UILabelProperty === "NoAvailableHomes")[0]?.Label}`
      : labelsInfo?.available_Units > 1
      ? `${buttonsLabel
          ?.filter((btn) => btn.UILabelProperty === "Ledige")[0]
          ?.Label?.toLowerCase()} ${buttonsLabel
          ?.filter((btn) => btn.UILabelProperty === "boliger")[0]
          ?.Label?.toLowerCase()}`
      : `${availableTranslation?.toLowerCase()} ${buttonsLabel
          ?.filter((btn) => btn.UILabelProperty === "bolig")[0]
          ?.Label?.toLowerCase()}`;

  useEffect(() => {
    setLabHovered(isHovered ? { name: labelsInfo?.URLSafe } : { name: "" });
  }, [isHovered]);

  if (isOverview && !rmode)
    return (
      <>
        <Pos c={c}>
          <Svg {...svg}>
            <Group {...line_angle}>
              <Path {...path1} />
            </Group>

            <CircleGroup {...circleGroup}>
              <Circle {...circ1}></Circle>
              <Circle {...circ2}></Circle>
              <Circle {...circ3}></Circle>
            </CircleGroup>
          </Svg>
          <Outer {...dl} xAxis={mobileLabelAxis[0]} yAxis={mobileLabelAxis[1]}>
            <Lab
              {...dl}
              clr={clr}
              ref={hoverRef}
              onClick={() => click(labelsInfo)}
            >
              <Text1 s={s} clr={clr}>
                {labelsInfo?.Presentation}
              </Text1>
              {/* {labelsInfo?.URLSafe !== "Etape 2" ? <Text2 s = {s} clr = {clr}>{`${labelsInfo?.available_Units} ${labelText}`}</Text2>
              : <Text2 clr = {clr}>Kommer snart</Text2>
              } */}
              <Text2 s={s} clr={clr}>
                {labelsInfo?.available_Units > 0
                  ? `${labelsInfo?.available_Units} ${labelText}`
                  : labelText}
              </Text2>
              {/* <Text3 clr={clr}>Klik her for at se dem</Text3> */}
            </Lab>
          </Outer>
        </Pos>
      </>
    );
};
